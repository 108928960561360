import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link } from "gatsby";

const ThumbnailGallery = styled.div`
  margin: 1em;
  width: 100%;
  img {
    max-height: 100px;
    margin: 0 2rem;
  }
  .thumbnail {
    /* padding: 0 0.5em; */
  }
  .activeThumbnail > img {
    border: 3px solid var(--highlight);
  }
  img:hover {
    transform: scale(1.1);
  }
`;

const handleImageClick = (e) => {
  document.querySelectorAll(".thumbnail").forEach((thumbnail) => {
    thumbnail.classList.remove("activeThumbnail");
  });
  e.target.classList.add("activeThumbnail");
};

export default function ImageThumbnailGallery({ artwork }) {
  const thumbnails = [
    getImage(artwork.image1.asset),
    getImage(artwork.image2?.asset),
    getImage(artwork.image3?.asset),
  ];
  console.log(thumbnails);

  if (thumbnails[1] !== undefined) {
    return (
      <ThumbnailGallery>
        {thumbnails.map((thumbnail, i) => {
          if (thumbnail !== undefined) {
            return (
              <GatsbyImage
                onClick={handleImageClick}
                key={`artwork${i}`}
                className="image"
                image={thumbnail}
                alt={`${artwork.title} - Thumbnail image ${i + 1}`}
                class={i === 0 ? "thumbnail activeThumbnail" : "thumbnail"}
                height="100"
              />
            );
          }
        })}
      </ThumbnailGallery>
    );
  } else {
    return <ThumbnailGallery />;
  }
}
