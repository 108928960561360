import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import ImageThumbnailGallery from "../components/ImageThumbnailGallery";
import MainImage from "../components/MainImage";

const ArtworkLayout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #333;
  max-width: 1100px;
  padding: 2em;
  margin: 2em auto;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  h1 {
    padding: none;
    margin: 0;
    span {
      font-size: 0.5em;
    }
  }
  h2 {
    font-size: 1em;
    font-weight: 400;
    margin: 3rem 0;
  }
  h3 {
    font-weight: 400;
    padding: none;
  }
`;

const ImageContainer = styled.div`
  height: 620px;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 10px;
    /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); */
  }
  img:nth-child(1) {
    padding: 10px;
  }
`;

const TextContainer = styled.div``;

export default function artworks({ data }) {
  const artwork = data.artworkData;
  //Images - there'll always be an image 1, image 2 and 3 are optional

  let currentImage = 1;

  return (
    <ArtworkLayout>
      <ImageContainer>
        <MainImage mainImage={artwork.image1.asset} />
        <br />
        <ImageThumbnailGallery artwork={artwork} />
      </ImageContainer>
      <TextContainer>
        <h1>
          {artwork.title}{" "}
          {artwork.releaseDate ? <span>({artwork.releaseDate})</span> : ""}
        </h1>
        <h3>
          <Link to={`../../artists/${artwork.artist.slug.current}`}>
            {artwork.artist.name}
          </Link>
        </h3>
        <h2>{artwork.medium}</h2>
        {artwork.purchaseDate ? (
          <p>
            Added to the MOMH {artwork.room.name} in{" "}
            {artwork.purchaseDate?.substring(0, 4)}.
          </p>
        ) : (
          ""
        )}
      </TextContainer>
    </ArtworkLayout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    # artworks: sanityArt(slug: { current: { eq: "day-break-cathedral" } }) {
    artworkData: sanityArt(slug: { current: { eq: $slug } }) {
      medium
      purchaseDate
      releaseDate
      room {
        name
        slug {
          current
        }
      }
      slug {
        current
      }
      title
      id
      image1 {
        asset {
          gatsbyImageData(placeholder: DOMINANT_COLOR, height: 500)
        }
      }
      image2 {
        asset {
          gatsbyImageData(placeholder: DOMINANT_COLOR, height: 100)
        }
      }
      image3 {
        asset {
          gatsbyImageData(placeholder: DOMINANT_COLOR, height: 100)
        }
      }
      artist {
        name
        slug {
          current
        }
      }
    }
  }
`;
