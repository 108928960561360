import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function MainImage({ mainImage }) {
  return (
    <GatsbyImage
      className="image"
      image={getImage(mainImage)}
      alt={`Main Image`}
    />
  );
}
